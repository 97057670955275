import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Video = makeShortcode("Video");
const Link = makeShortcode("Link");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-the-gift",
      "style": {
        "position": "relative"
      }
    }}>{`🎁 The Gift`}<a parentName="h1" {...{
        "href": "#-the-gift",
        "aria-label": " the gift permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`After describing `}<a parentName="p" {...{
        "href": "/learn/module-6/serenity/#how-does-this-fit-into-kernel"
      }}>{`Ethereum 2.0 as our generation's elder game of economic penalties`}</a>{`,
it may seem like there is nothing left to say. However, there is one bonus side-mission left;
one more extension pack meant to give you a unique means of reading our new interplanetary plays.`}</p>
    <p>{`For seven weeks, we've been discussing the `}<a parentName="p" {...{
        "href": "/learn/module-0/play-of-pattern"
      }}>{`play of complementary opposites`}</a>{`,
extending our awareness of the spectrum of consciousness and examining more intimately the
trade-offs we make in every moment. But what, really, are these opposites? What informs their
opposite-ness? What is the real nature of their relationship?`}</p>
    <blockquote>
      <p parentName="blockquote">{`The opposition is, as it were, between container and contained, between the background and the stage, between `}<a parentName="p" {...{
          "href": "https://vedabase.io/en/library/bg/13/"
        }}>{`the field and the players moving on it`}</a>{`. The good and the evil play their opposing parts on the field which remains neutral and indifferent and "open" or "empty". It is like rain that falls on the just and the unjust. -- Daisetz Suzuki`}</p>
    </blockquote>
    <p>{`As Hua-Ching Ni says, "these polarized energies `}<strong parentName="p">{`contain`}</strong>{` and complement each other". When you notice how each complementary opposite not only illuminates the other pole, but contains it fully, you are moving beyond simplistic dualism. Each pole has many attributes and is, in itself, a complex process, but "by understanding them and how they interact with each other, one can learn the truth of any situation and determine the proper action to take."`}</p>
    <p>{`We can, in modern cognitive science terms (inspired by ancient myth), come to realise not just complementarity, but `}<a parentName="p" {...{
        "href": "https://youtu.be/IZ-tHaHfB8A?t=5280"
      }}>{`coincidence`}</a>{`: `}<strong parentName="p">{`this here and now`}</strong>{`, which is the presence of eternity. Critically, it is not about culmination or resolution, but `}<a parentName="p" {...{
        "href": "https://youtu.be/IZ-tHaHfB8A?t=5649"
      }}>{`infinities accessible through humble opponent processing`}</a>{`.`}</p>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><em parentName="p">{`We wrap the origin in two`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`lines, in order to experience`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`neither self nor world`}</em><br parentName="p"></br>{`
`}<a parentName="p" {...{
            "href": "/learn/module-metta/relating-kernel/#the-logic-lyrical"
          }}><em parentName="a">{`nor both, nor ne(i)ther.`}</em></a>{`  `}</p>
        <p><em parentName="p">{`This is removal,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`not the negative,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`which is finding emptiness`}</em><br parentName="p"></br>{`
`}<em parentName="p"><a parentName="em" {...{
              "href": "https://jackkornfield.com/heart-wisdom-ep-68-path-of-joy/"
            }}>{`joyful, loving`}</a>{`.`}</em>{`  `}</p>
        <p><em parentName="p">{`So, who is the user?`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`(n)one.`}</em>{`  `}</p>
      </Box>
    </InfoBlock>
    <blockquote>
      <p parentName="blockquote">{`“Out `}<a parentName="p" {...{
          "href": "https://www.youtube.com/watch?v=-66_0MuykH4&t=2542s"
        }}>{`beyond ideas of wrongdoing and rightdoing`}</a>{` there is a field. I'll meet you there. When the soul lies down in that grass the world is too full to talk about.” -- Jalāl ad-Dīn Muhammad Rūmī`}</p>
    </blockquote>
    <h2 {...{
      "id": "living-spirit",
      "style": {
        "position": "relative"
      }
    }}>{`Living Spirit`}<a parentName="h2" {...{
        "href": "#living-spirit",
        "aria-label": "living spirit permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Vitalik mentioned last week the importance of asymmetric defense as the basic premise required to uphold "the ideology of the cypherpunk spirit". So, let's look more carefully at those two loaded words: `}<strong parentName="p">{`ideology`}</strong>{` and `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=fInPXzx48jE"
      }}><strong parentName="a">{`spirit`}</strong></a>{`.`}</p>
    <p>{`To do so, we'll consider a critical and often overlooked part of hacker and cypherpunk culture: gift-giving. Once we've traversed this ancient part of both our shared history and individual psyches, we'll discuss the inertia of language which makes the above two words so difficult to deal with. Then we'll close with a consideration of what a universal language deployed on a shared, transparent, and ownerless global network means when playing increasingly principled multiplayer games.`}</p>
    <p>{`Thank you for your kind attention. This has been an amazing exploration, and we wish you so
very well as we all adventure together into `}<a parentName="p" {...{
        "href": "/learn/module-4/the-garden/#shared-realities"
      }}>{`new architectures`}</a>{`
of `}<a parentName="p" {...{
        "href": "/learn/module-3/time/"
      }}>{`time`}</a>{` and more numinous digital-economic spaces. `}</p>
    <blockquote>
      <p parentName="blockquote">{`Darkness and brightness—absolute and relative—are a pair of opposites, like front and back feet when we walk. This is a very good way of explaining oneness, or the actual function of a pair of opposites. It expresses how we apply pairs of opposites, like delusion and enlightenment, reality and idea, good and bad, weak and strong, in our everyday practice. People who feel they are strong may find it difficult to be weak. People who feel they are weak may never try to be strong. That is quite usual. But sometimes we should be strong and sometimes we should be weak. If you remain weak always or if you always want to be strong, then you cannot be strong in the true sense." - `}<a parentName="p" {...{
          "href": "https://terebess.hu/zen/mesterek/Branching-Streams.pdf"
        }}>{`Shunryu Suzuki`}</a></p>
    </blockquote>
    <Video src="https://www.youtube-nocookie.com/embed/RDGK5b2Vpyw" mdxType="Video" />
    <h2 {...{
      "id": "remembrance",
      "style": {
        "position": "relative"
      }
    }}>{`Remembrance`}<a parentName="h2" {...{
        "href": "#remembrance",
        "aria-label": "remembrance permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`As he has played such a vital role in many of the currents which flow beneath this syllabus,
we would like to dedicate this module to `}<a parentName="p" {...{
        "href": "/conversation/hospitality/"
      }}>{`the intergalactic memory of David Graeber`}</a>{`. Consider taking some time to read one of his first and most moving works, `}<Link to="https://abahlali.org/files/Graeber.pdf" mdxType="Link">{`Fragments of An Anarchist Anthropology`}</Link>{`. In speaking about the imagination as a political principle, he wrote:`}</p>
    <blockquote>
      <p parentName="blockquote">{`One obvious role for a radical intellectual is to do precisely that: to look at those who are creating viable alternatives, try to figure out what might be the larger implications of what they are (already) doing, and then offer those ideas back, not as prescriptions, but as contributions, possibilities — as gifts `}{`[...]`}{` Such a project would have to have two aspects: one ethnographic, one utopian, suspended in a `}<a parentName="p" {...{
          "href": "/learn/module-0/conversation/#old-gifts-anew"
        }}>{`constant dialogue`}</a>{`.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Economies without markets were not based on calculation, but on a refusal to calculate; they were rooted in an ethical system which consciously rejected most of what we would consider the basic principles of economics. It was not that they had not yet learned to seek profit through the most efficient means. They would have found the very premise that the point of an economic transaction — at least, one with someone who was not your enemy — was to seek the `}<Link to="https://www.brainpickings.org/2014/07/07/buddhist-economics-schumacher/" mdxType="Link">{`greatest profit deeply offensive`}</Link>{`. `}</p>
    </blockquote>
    <h2 {...{
      "id": "week-7-firesides",
      "style": {
        "position": "relative"
      }
    }}>{`Week 7 Firesides`}<a parentName="h2" {...{
        "href": "#week-7-firesides",
        "aria-label": "week 7 firesides permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Voices of KB7 - November 3rd, 2022`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/Pt3JPvV8fY0" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Voices of KB5 - March 31st, 2022`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/OJ0FqnQ8VZw?start=172" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Lani Trock & Toby Shorin - November 18, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/Bhda03-YOjk?start=268" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Britt Harris - July 8, 2020`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/wVDD2RWlr-8" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Vitalik Buterin - Aug 27, 2020`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/6u1xZqOe5pE?start=276" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Andy Tudhope - March 4, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/XHCv7e4NRAM?start=220" mdxType="Video" />
      </Box>
    </Column>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      